import { sortProductLists } from "components/helpers/sortProductLists";
import { useMemo } from "react";
import useCustomerProductListsWithContracts from "../../../_redux/hooks/useCustomerProductListsWithContracts";

const useSortedCustomerProductList = () => {
  const customerProductLists = useCustomerProductListsWithContracts();

  return useMemo(
    () => sortProductLists([...customerProductLists]),
    [customerProductLists]
  );
};

export default useSortedCustomerProductList;
