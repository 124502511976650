import { useState, useEffect, useMemo } from "react";
import { useAppSelector } from "_redux/hooks";
import {
  currentOrganizationIdSelector,
  wcTokenSelector,
} from "_redux/selectors/user";
import { useSite } from "_foundationExt/hooks/useSite";
import eSpotService from "_foundation/apis/transaction/eSpot.service";
import { ESpotData, ESpotResponse } from "types/Marketing";
import { useAbortControllers } from "_foundationExt/hooks";
import { useLocation } from "react-router-dom";
import { pickBy } from "lodash-es";
import { isCanceledError, isAuthError } from "_foundationExt/axios/axiosConfig";
import isInstanaActive from "tools/isInstanaActive";
import mapMarketingSpotData, {
  MapMarketingSpotDataReturnType,
} from "./mapMarketingSpotData";

export const createFindByNameParameters = ({
  emsName,
  storeId,
  catalogId,
  signal,
  searchParams,
  categoryId,
  productId,
  organizationId,
  useCatalogEntryId = true,
  useCatalogGroupId = true,
}: {
  emsName: string;
  storeId: string;
  catalogId: string;
  signal: AbortSignal;
  searchParams?: URLSearchParams;
  categoryId?: string;
  productId?: string;
  organizationId?: string;
  useCatalogEntryId?: boolean;
  useCatalogGroupId?: boolean;
}) => {
  const searchTerm = searchParams?.get("searchTerm");
  const parameters = {
    name: emsName,
    storeId,
    catalogId,
    query: {
      categoryId,
      productId,
      DM_ReqCmd: (() => {
        if (searchTerm) {
          return "SearchDisplay";
        }
        if (categoryId && !productId) {
          return "CategoryDisplay";
        }
        if (productId) {
          return "ProductDisplay";
        }

        return undefined;
      })(),
      DM_ReturnCatalogEntryId: useCatalogEntryId,
      DM_ReturnCatalogGroupId: useCatalogGroupId,
      DM_ActiveOrganization: organizationId,
    } as Record<string, unknown>,
    signal,
  };

  // for url parameters.
  searchParams?.forEach((value, key) => {
    parameters.query[key] = value;
  });

  // remove undefined values from parameters.query
  parameters.query = pickBy(parameters.query, (v) => v !== undefined);

  return parameters;
};

const fetchESpotData = async ({
  emsName,
  storeId,
  catalogId,
  defaultLanguageId,
  setData,
  setError,
  setLoading,
  signal,
  searchParams,
  categoryId,
  productId,
  organizationId,
  setFound,
  useCatalogEntryId,
  useCatalogGroupId,
}: {
  emsName: string;
  storeId: string;
  catalogId: string;
  defaultLanguageId: string;
  setData: (data?: ESpotData[]) => void;
  setError: (error: boolean) => void;
  setLoading: (loading: boolean) => void;
  signal: AbortSignal;
  searchParams: URLSearchParams;
  categoryId?: string;
  productId?: string;
  organizationId?: string;
  setFound?: (found: boolean) => void;
  useCatalogEntryId?: boolean;
  useCatalogGroupId?: boolean;
}) => {
  setLoading(true);

  const parameters = createFindByNameParameters({
    emsName,
    storeId,
    catalogId,
    signal,
    searchParams,
    categoryId,
    productId,
    organizationId,
    useCatalogEntryId,
    useCatalogGroupId,
  });

  try {
    const response = await eSpotService.findByName(parameters);

    if (response.status !== 200) {
      setError(true);
    }

    const espotResponse = response.data as ESpotResponse;
    const marketingSpotData = espotResponse?.MarketingSpotData?.find(Boolean);

    let data: MapMarketingSpotDataReturnType;
    if (marketingSpotData) {
      data = await mapMarketingSpotData(
        marketingSpotData,
        storeId,
        defaultLanguageId,
        setError
      );
    }

    if (data && data.length > 1) {
      const highestPrio = Math.max(...data.map((o) => o.prio));
      data = data.filter((o) => o.prio === highestPrio);
    }

    if (data && data.length > 1) {
      data = data.sort((a) => {
        if (a.type.includes("MarketingContent")) return -1;
        return 1;
      });
    }

    if (data && data.length > 0 && categoryId) {
      for (let i = 0; i < data.length; i++)
        data[i].products = data[i].products.sort((a, b) => {
          if (a.parentCatgroupsPath && b.parentCatgroupsPath) {
            if (a.parentCatgroupsPath.includes(categoryId)) return -1;
            return 1;
          }
          return 0;
        });
    }

    if (data && data.length > 0 && setFound) {
      setFound(true);
    }

    setData(data);
    setLoading(false);
  } catch (error) {
    if (
      error instanceof Error &&
      isInstanaActive() &&
      !isCanceledError(error) &&
      !isAuthError(error)
    ) {
      ineum("reportError", error, {
        componentStack: error.stack,
      });
    }
  }
};

interface UseFetchESpotDataByFindByNameResponse {
  data?: ESpotData[];
  error: boolean;
  loading: boolean;
  found: boolean;
}

interface UseFetchESpotDataByFindByNameRequest {
  emsName: string;
  categoryId?: string;
  productId?: string;
  useCatalogEntryId?: boolean;
  useCatalogGroupId?: boolean;
}

const useFetchESpotDataByFindByName = (
  request: UseFetchESpotDataByFindByNameRequest
): UseFetchESpotDataByFindByNameResponse => {
  const {
    emsName,
    categoryId,
    productId,
    useCatalogEntryId,
    useCatalogGroupId,
  } = request;
  const { currentSite } = useSite();
  const wcToken = useAppSelector(wcTokenSelector);
  const [data, setData] = useState<ESpotData[] | undefined>(undefined);
  const [error, setError] = useState<boolean>(false);
  const [loading, setLoading] = useState(true);
  const [found, setFound] = useState(false);
  const abortControllers = useAbortControllers();
  const currentOrganizationId = useAppSelector(currentOrganizationIdSelector);

  // for url parameters
  const { search = "" } = useLocation();

  const searchParams = useMemo(() => new URLSearchParams(search), [search]);

  useEffect(() => {
    const { signal } = abortControllers.create();
    if (
      currentSite?.storeID &&
      currentSite?.catalogID &&
      currentSite?.defaultLanguageID
    ) {
      fetchESpotData({
        emsName,
        storeId: currentSite.storeID,
        catalogId: currentSite.catalogID,
        defaultLanguageId: currentSite.defaultLanguageID,
        setData,
        setError,
        setLoading,
        signal,
        searchParams,
        categoryId,
        productId,
        organizationId: currentOrganizationId,
        setFound,
        useCatalogEntryId,
        useCatalogGroupId,
      });
    }
  }, [
    searchParams,
    currentSite?.storeID,
    currentSite?.catalogID,
    currentSite?.defaultLanguageID,
    emsName,
    productId,
    categoryId,
    useCatalogEntryId,
    useCatalogGroupId,
    currentOrganizationId,
    wcToken,
    abortControllers,
  ]);

  return useMemo(
    () => ({ data, error, loading, found }),
    [data, error, loading, found]
  );
};

export default useFetchESpotDataByFindByName;
