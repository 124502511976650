import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "_redux/hooks";
import { UPDATE_PRODUCTLIST_FILTER } from "_redux/actions/user";
import {
  loginStatusSelector,
  userInitStatusSelector,
} from "_redux/selectors/user";
import { useLocation, Link } from "react-router-dom";
import offerService from "_foundationExt/apis/transaction/offer.service";
import { useSite } from "_foundationExt/hooks/useSite";
import { Offer } from "types/Offer";
import { ROUTES } from "constants/routes";
import { useTranslation } from "react-i18next";
import {
  StyledButton,
  StyledContainer,
  StyledDivider,
} from "components/StyledUI";
import { Typography } from "@mui/material";
import ProductSearchContainer from "./ProductSearchContainer";
import BreadcrumbSection from "./BreadcrumbSection";

interface ProductSearchContainerProps {
  offerNumber: number;
}

const OfferSearchContainer: React.FC<ProductSearchContainerProps> = ({
  offerNumber,
}) => {
  const { currentSite } = useSite();
  const { t } = useTranslation();
  const initSelector = useAppSelector(userInitStatusSelector);
  const userLoggedIn = useAppSelector(loginStatusSelector);
  const [offer, setOffer] = useState<Offer>();
  const [notFound, setNotFound] = useState<boolean>(false);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (currentSite?.storeID && initSelector) {
      const fetchData = async () => {
        dispatch(UPDATE_PRODUCTLIST_FILTER([]));
        const response = await offerService.getOffers();
        if (response.data?.offers?.length) {
          const offerFound = response.data.offers.find(
            (item) => item.offerNumber === offerNumber
          ) as Offer | undefined;

          if (offerFound) {
            setOffer(offerFound);
            setNotFound(false);
          } else {
            setOffer(undefined);
            setNotFound(true);
          }
        }
      };

      fetchData();
    }
  }, [currentSite?.storeID, initSelector, userLoggedIn, offerNumber, dispatch]);

  const location = useLocation();
  const queryString = new URLSearchParams(location.search);
  const categoryId = queryString.get("categoryId");

  if (notFound) {
    const breadcrumb = [
      { label: `${t("sites.offers")}`, value: "", link: ROUTES.OFFERS },
    ];

    return (
      <>
        <BreadcrumbSection breadcrumbData={breadcrumb} />

        <StyledContainer size="small">
          <Typography>{t("offers.notFound")}</Typography>
          <StyledDivider light />
          <StyledButton component={Link} to={ROUTES.OFFERS}>
            {t("offers.to")}
          </StyledButton>
        </StyledContainer>
      </>
    );
  }

  if (offer) {
    return (
      <ProductSearchContainer
        searchTerm="*"
        partNumbers=""
        overrideHeadline={null}
        categoryId={categoryId || null}
        productListId={offer.id}
        offer={offer}
      />
    );
  }

  return null;
};

export default OfferSearchContainer;
