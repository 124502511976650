// react
import React from "react";
import { ROUTES } from "constants/routes";
// redux
import { useSite } from "_foundationExt/hooks/useSite";
import { useAppSelector } from "_redux/hooks";
import {
  basketPredictionSelector,
  userStockDeliveryDateDate,
  userOrderHistorySelector,
  userOrgIdSelector,
} from "_redux/selectors/user";

import { Breakpoint } from "@mui/material/styles";
import {
  StyledCard,
  StyledCardContent,
  StyledLink,
  StyledProtectable,
  UnstyledLink,
} from "components/StyledUI";
import { Box, Avatar, GridSize, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
// comopo
import {
  formatLongDate,
  formatCurrency,
  formatDateLocal,
} from "tools/stringUtils";
import { useTranslation } from "react-i18next";
import { OrderHistory } from "types/Order";
import { ReactComponent as PredictivBasketIcon } from "assets/icons/ki-avatar.svg";
import { isEmpty } from "lodash-es";
import { tss } from "tss-react/mui";
import { DeliveryDateBox } from "../deliveryDateBox";

const useStyles = tss.create(({ theme }) => ({
  avatar: {
    backgroundColor: theme.palette.primary.main,
    width: "72px",
    height: "72px",
    "& svg": {
      width: "72px",
      height: "72px",
    },
  },
  center: {
    alignItems: "center",
  },
  rounded: {
    backgroundColor: theme.palette.grey[800],
    width: "72px",
    height: "72px",
  },
  day: {
    backgroundColor: theme.palette.primary.main,
    fontSize: "12px",
    padding: "10px 0 6px",
    textAlign: "center",
    width: "72px",
  },
  date: {
    padding: "12px 0",
    textAlign: "center",
    width: "72px",
  },
  item: {
    padding: theme.spacing(1),
    backgroundColor: theme.palette.grey[800],
    borderRadius: theme.spacing(0.5),
    marginTop: theme.spacing(0.5),
    transition: theme.transitions.create(["background-color"]),
    "& p": {
      color: "#fff",
    },
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },
  },
  content: {
    paddingBottom: "0px !important",
  },
}));

const OrderInformation: React.FC<
  Partial<Record<Breakpoint, boolean | GridSize>>
> = ({ ...rest }) => {
  const { currentSite } = useSite();
  const { classes } = useStyles();
  const { t } = useTranslation();
  const deliveryDate = useAppSelector(userStockDeliveryDateDate);
  const basketPrediction = useAppSelector(basketPredictionSelector);
  const [date, setDate] = React.useState<null | Date>(null);

  const showPrediction =
    !isEmpty(basketPrediction?.cluster) ||
    !isEmpty(basketPrediction?.individual);

  const getWeekday = (d: Date) => {
    if (d.getDay() === 0) return "Sonntag";
    if (d.getDay() === 1) return "Montag";
    if (d.getDay() === 2) return "Dienstag";
    if (d.getDay() === 3) return "Mittwoch";
    if (d.getDay() === 4) return "Donnerstag";
    if (d.getDay() === 5) return "Freitag";
    if (d.getDay() === 6) return "Samstag";
    return "";
  };

  const showOrderItem = (index: number) => {
    const itemsToShow: number = showPrediction ? 3 : 6;
    return index < itemsToShow;
  };

  React.useEffect(() => {
    if (currentSite && deliveryDate) setDate(new Date(deliveryDate));
  }, [currentSite, deliveryDate]);

  const organizationId = useAppSelector(userOrgIdSelector);
  const orderHistoryListData = useAppSelector(userOrderHistorySelector);
  const [orderHistoryData, setOrderHistoryData] =
    React.useState<OrderHistory>();

  React.useEffect(() => {
    if (currentSite && orderHistoryListData)
      setOrderHistoryData(orderHistoryListData);
  }, [currentSite, orderHistoryListData]);

  return (
    <Grid {...rest} container className="blackbox">
      <StyledCard dark>
        <StyledCardContent className={classes.content}>
          <Grid container>
            {basketPrediction && (
              <Grid container xs={12} marginBottom={2}>
                <Grid xs={12}>
                  <Box mb={2}>
                    <Typography variant="h3">
                      {t("home.widget.orderTemplate")}
                    </Typography>
                  </Box>
                </Grid>
                <Grid container xs={12} className={classes.center}>
                  <Grid xs>
                    <Avatar className={classes.avatar}>
                      <PredictivBasketIcon />
                    </Avatar>
                  </Grid>
                  <Grid xs={8}>
                    <StyledLink whiteText small to={ROUTES.PRODUCT_PREDICTION}>
                      {t("home.widget.showOrderTemplate")}
                    </StyledLink>
                  </Grid>
                </Grid>
              </Grid>
            )}

            <Grid container xs={12}>
              <Grid xs={12}>
                <Box mb={2}>
                  <Typography variant="h3">
                    {t("cart.nextDeliveryDay")}
                  </Typography>
                </Box>
              </Grid>
              {date ? (
                <Grid container xs={12} className={classes.center}>
                  <Grid xs>
                    <Avatar variant="rounded" className={classes.rounded}>
                      <Grid container>
                        <Grid xs={12} className={classes.day}>
                          {getWeekday(date)}
                        </Grid>
                        <Grid className={classes.date}>{date.getDate()}</Grid>
                      </Grid>
                    </Avatar>
                  </Grid>
                  <Grid xs={8}>
                    <Typography variant="h3">
                      <Box>{formatDateLocal(date, "dd.MM.yyyy")}</Box>
                    </Typography>
                    <DeliveryDateBox variant="text" />
                  </Grid>
                </Grid>
              ) : (
                <Grid xs={12} className={classes.center}>
                  <DeliveryDateBox variant="text" />
                </Grid>
              )}
            </Grid>

            <Grid container xs={12} marginTop={3}>
              <Grid xs={12}>
                <Box mb={1.5} mt={1}>
                  <Typography variant="h3">
                    {t("myAccount.orderhistory")}
                  </Typography>
                </Box>
              </Grid>

              {orderHistoryData && (
                <Grid xs={12}>
                  {orderHistoryData.size > 0 &&
                    orderHistoryData.entries.map(
                      (item, index) =>
                        showOrderItem(index) && (
                          <UnstyledLink
                            key={`historyItem_${item.orderHistoryId}`}
                            to={`${ROUTES.ACCOUNT_ORDERHISTORY_DETAILS}/${organizationId}/${item?.orderHistoryId}`}>
                            <div
                              className={classes.item}
                              key={item.orderHistoryId}>
                              <Typography variant="body2">
                                <span>{formatLongDate(item.orderDate)}</span>
                                <StyledProtectable permission="show.price">
                                  <span
                                    style={{
                                      float: "right",
                                      display: "block",
                                    }}>
                                    {formatCurrency(item.orderAmount)}
                                  </span>
                                </StyledProtectable>
                              </Typography>
                            </div>
                          </UnstyledLink>
                        )
                    )}
                  {orderHistoryData.size <= 0 && (
                    <div className={classes.item}>
                      <Typography variant="body2">
                        <span>{t("myAccount.orderHistory.emptyList")}</span>
                      </Typography>
                    </div>
                  )}
                </Grid>
              )}

              <Grid xs={12}>
                <Box mt={2}>
                  <StyledLink whiteText small to={ROUTES.ACCOUNT_REPORTS}>
                    {t("myAccount.showHistory")}
                  </StyledLink>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </StyledCardContent>
      </StyledCard>
    </Grid>
  );
};

export default OrderInformation;
