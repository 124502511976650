import React, { ChangeEventHandler, FC, useCallback, useState } from "react";
import { useAppDispatch } from "_redux/hooks";

import { StyledFormCheckbox } from "components/StyledUI";
import { truncate } from "tools/stringUtils";
import { CustomerProductlist } from "types/CustomerProductlist";
import { UPDATE_PRODUCTLIST_FILTER } from "_redux/actions/user";

interface FilterOptionProductlistProps {
  filterEntry: CustomerProductlist;
  selectedProductlistFilter: CustomerProductlist[];
}

const FilterOptionProductlist: FC<FilterOptionProductlistProps> = ({
  filterEntry,
  selectedProductlistFilter,
}) => {
  const dispatch = useAppDispatch();
  const [checked] = useState(() =>
    selectedProductlistFilter.includes(filterEntry)
  );

  const onChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      if (event.target.checked) {
        if (selectedProductlistFilter.includes(filterEntry) === false) {
          const updatedFilterList = [...selectedProductlistFilter];
          updatedFilterList.push(filterEntry);
          dispatch(UPDATE_PRODUCTLIST_FILTER(updatedFilterList));
        }
      } else {
        const updatedFilterList = selectedProductlistFilter.filter(
          (list) => list.id !== filterEntry.id
        );
        dispatch(UPDATE_PRODUCTLIST_FILTER(updatedFilterList));
      }
    },
    [dispatch, filterEntry, selectedProductlistFilter]
  );

  return (
    <StyledFormCheckbox
      key={`filterOption_productList_dropdown_${filterEntry.id}`}
      size="small"
      checkboxProps={{
        checked,
        onChange,
        name: filterEntry.id,
      }}
      label={truncate(filterEntry.name, 31)}
    />
  );
};

export default FilterOptionProductlist;
