import React, { useEffect } from "react";
import { useLiveQuery } from "dexie-react-hooks";
import usePWADisplayMode from "pwa/displayMode/usePWADisplayMode";
import offlineSyncEnabled from "pwa/offline/common/OfflineSyncEnabled";
import SyncNotification from "pwa/offline/sync/components/SyncNotification";
import lastRunSyncTimestamp from "pwa/offline/sync/lastRun/lastRunSyncTimestamp";

import { SHOW_DIALOG_ACTION } from "_redux/actions/site";
import { isSyncInformationType, SyncInformationType } from "_redux/reducers";
import {
  loginStatusSelector,
  userIsCentralPurchaserSelector,
  wcTokenSelector,
  wcTrustedTokenSelector,
} from "_redux/selectors/user";
import { useAppDispatch, useAppSelector } from "_redux/hooks";
import { useEFoodConfig } from "_foundationExt/hooks";
import { useOnlineStatus } from "_foundationExt/hooks/useOnlineStatus";
import { selectSyncManuallyTriggered } from "_redux/selectors/site";

const sevenDaysInMilliseconds = 7 * 24 * 60 * 60 * 1000;

const OfflineSyncWatcher: React.FC = () => {
  const dispatch = useAppDispatch();
  const { isPWA } = usePWADisplayMode();
  const userLoggedIn = useAppSelector(loginStatusSelector);
  const eFoodConfig = useEFoodConfig();
  const wcToken = useAppSelector(wcTokenSelector);
  const wcTrustedToken = useAppSelector(wcTrustedTokenSelector);
  const isCentralPurchaser = useAppSelector(userIsCentralPurchaserSelector);
  const triggerSyncManually = useAppSelector(selectSyncManuallyTriggered);
  const { isOnline } = useOnlineStatus();
  const lastRunTimestamp = useLiveQuery(
    lastRunSyncTimestamp.get,
    [],
    Date.now()
  );
  const isOfflineSyncEnabled = useLiveQuery(offlineSyncEnabled.get, [], false);

  const requestOfflineModeConsent =
    !isCentralPurchaser &&
    userLoggedIn &&
    isOnline &&
    isPWA &&
    isOfflineSyncEnabled == null;

  const syncNeeded =
    !lastRunTimestamp ||
    lastRunTimestamp < Date.now() - sevenDaysInMilliseconds;

  const triggerSync =
    (syncNeeded || triggerSyncManually) &&
    isOfflineSyncEnabled === true &&
    !isCentralPurchaser &&
    userLoggedIn &&
    wcToken != null &&
    wcTrustedToken != null &&
    isOnline &&
    isPWA;

  const variant: SyncInformationType = isSyncInformationType(
    eFoodConfig?.offlineSyncInformationType
  )
    ? eFoodConfig.offlineSyncInformationType
    : "notification";

  useEffect(() => {
    if (requestOfflineModeConsent) {
      dispatch(
        SHOW_DIALOG_ACTION({
          type: "AllowSyncDialog",
          closeable: false,
        })
      );
    }
  }, [dispatch, requestOfflineModeConsent]);

  useEffect(() => {
    if (isOfflineSyncEnabled && variant === "dialog" && triggerSync) {
      dispatch(
        SHOW_DIALOG_ACTION({
          type: "SyncOfflineData",
          closeable: true,
          params: {
            cancelable: true,
            closeOnSuccess: true,
          },
        })
      );
    }
  }, [dispatch, variant, isOfflineSyncEnabled, triggerSync]);

  if (isOfflineSyncEnabled && variant === "notification") {
    return (
      <SyncNotification
        cancelable
        triggerSync={triggerSync}
        manuallyTriggered={triggerSyncManually}
      />
    );
  }

  return null;
};

export default OfflineSyncWatcher;
