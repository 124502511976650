import React, { FC } from "react";
import Grid from "@mui/material/Unstable_Grid2";

import Quantity from "components/quantity/Quantity";
import { ProductStandardQuantity } from "components/productStandardQuantity";
import { Product } from "types/Product";
import { CustomerProductlist } from "types/CustomerProductlist";
import { TrackShowType } from "components/matomo";

import { tss } from "tss-react/mui";

const useStyles = tss
  .withParams<{ editMode?: boolean }>()
  .create(({ theme, editMode }) => ({
    wrapper: {
      [theme.breakpoints.down("sm")]: {
        flexGrow: 1,
      },
      [theme.breakpoints.down(385)]: {
        marginLeft: "-30px",
      },
      [theme.breakpoints.down(321)]: {
        flexBasis: "100%",
      },
    },
    grid: {
      [theme.breakpoints.down("sm")]: {
        flexGrow: 1,
      },
      display: "flex",
      flexDirection: editMode ? "row" : "column",
      alignItems: editMode ? "start" : "end",
      justifyContent: editMode ? "end" : "start",
    },
  }));

interface QuantitySectionProps {
  product: Product;
  isAccountListView?: boolean;
  selectedCustomerProductList?: CustomerProductlist | null;
  trackType?: TrackShowType;
  editMode?: boolean;
}

const QuantitySection: FC<QuantitySectionProps> = ({
  isAccountListView,
  selectedCustomerProductList,
  product,
  trackType,
  editMode,
}) => {
  const { classes } = useStyles({
    editMode,
  });

  if (isAccountListView && selectedCustomerProductList?.type) {
    return (
      <Grid xs={6} sm={8} md={8} lg={4} className={classes.grid}>
        <ProductStandardQuantity
          product={product}
          selectedCustomerProductList={selectedCustomerProductList}
          editMode={editMode}
        />
        {!editMode && (
          <Quantity data={product} type="list" trackType={trackType} />
        )}
      </Grid>
    );
  }

  return (
    <Grid xs={8} lg={4} className={classes.wrapper}>
      <Quantity data={product} type="list" trackType={trackType} />
    </Grid>
  );
};

export default QuantitySection;
