import { useMemo } from "react";
import { useAppSelector } from "_redux/hooks";
import {
  userActiveOrgIdSelector,
  userEntitledOrganizationsSelector,
  userIsCentralPurchaserSelector,
  userOrgIdSelector,
} from "_redux/selectors/user";

const useCentralPurchaser = () => {
  const isCentralPurchaser = useAppSelector(userIsCentralPurchaserSelector);
  const entitledOrganizations = useAppSelector(
    userEntitledOrganizationsSelector
  );
  const activeOrganizationId = useAppSelector(userActiveOrgIdSelector);
  const organizationId = useAppSelector(userOrgIdSelector);

  return useMemo(
    () => ({
      isCentralPurchaser,
      entitledOrganizations,
      activeOrganizationId,
      organizationId,
    }),
    [
      isCentralPurchaser,
      entitledOrganizations,
      activeOrganizationId,
      organizationId,
    ]
  );
};

export default useCentralPurchaser;
