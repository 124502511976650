import { useMemo } from "react";
import { customerOffers } from "_redux/selectors/user";
import { useAppSelector } from "_redux/hooks";
import { CUSTOMER_PRODUCTLIST_TYPE_OFFER } from "types/CustomerProductlist";
import useCustomerProductListsWithContracts from "./useCustomerProductListsWithContracts";

const useCustomerProductListsWithContractsAndOffers = () => {
  const customerProductLists = useCustomerProductListsWithContracts();
  const offers = useAppSelector(customerOffers);

  return useMemo(() => {
    const productLists = [...customerProductLists];
    if (offers.length) {
      offers.forEach((offer) => {
        productLists.push({
          id: offer.id,
          name: offer.title,
          type: CUSTOMER_PRODUCTLIST_TYPE_OFFER,
        });
      });
    }
    return productLists;
  }, [customerProductLists, offers]);
};

export default useCustomerProductListsWithContractsAndOffers;
