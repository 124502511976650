import { useMemo } from "react";
import { TFunction } from "i18next";
import { useTranslation } from "react-i18next";

import {
  CUSTOMER_PRODUCTLIST_TYPE_CONTRACT,
  CUSTOMER_PRODUCTLIST_TYPE_OFFER,
} from "types/CustomerProductlist";
import { BreadcrumbData } from "components/StyledUI/StyledBreadcrumb/StyledBreadcrumb";
import useCustomerProductListsWithContractsAndOffers from "../../_redux/hooks/useCustomerProductListsWithContractsAndOffers";

const translateProductListType = (
  t: TFunction,
  customerProductListType?: string
): string => {
  switch (customerProductListType) {
    case CUSTOMER_PRODUCTLIST_TYPE_CONTRACT:
      return t("search.productlistType.XCO");
    case CUSTOMER_PRODUCTLIST_TYPE_OFFER:
      return t("search.productlistType.XOF");
    default:
      return t("search.productlistType.myArticle");
  }
};

const calculatePaginationProductNumbers = (
  page: number,
  pageSize: string,
  totalAmountOfProducts: number
) => {
  const numberOfFirstProduct = (page - 1) * parseInt(pageSize, 10) + 1;
  let numberOfLastProduct = numberOfFirstProduct + (parseInt(pageSize, 10) - 1);

  // important for the last page
  if (totalAmountOfProducts < numberOfLastProduct) {
    numberOfLastProduct = totalAmountOfProducts;
  }

  return { numberOfFirstProduct, numberOfLastProduct };
};

const useHeadline = (
  searchTerm: string | null,
  overrideHeadline: string | null,
  productListId: string | null,
  breadcrumbData: BreadcrumbData[] | null,
  searchOrderset: boolean,
  totalAmountOfProducts: number | null,
  page: number,
  pageSize: string
): string | null => {
  const { t } = useTranslation();
  const customerProductLists = useCustomerProductListsWithContractsAndOffers();

  return useMemo(() => {
    /* eslint-disable indent */
    const { numberOfFirstProduct = 0, numberOfLastProduct = 0 } =
      totalAmountOfProducts
        ? calculatePaginationProductNumbers(
            page,
            pageSize,
            totalAmountOfProducts
          )
        : {};
    /* eslint-enable indent */

    if (overrideHeadline) {
      return t("search.overrideHeadline", {
        headline: overrideHeadline,
        totalAmountOfProducts,
        numberOfFirstProduct,
        numberOfLastProduct,
      });
    }

    if (productListId) {
      const customerProductList = customerProductLists.find(
        (list) => list.id === productListId
      );
      const articleListType = translateProductListType(
        t,
        customerProductList?.type
      );

      return t("search.customerProductlistResultHeadline", {
        articleListType,
        articleListName: customerProductList?.name,
        totalAmountOfProducts,
        numberOfFirstProduct,
        numberOfLastProduct,
      });
    }

    if (breadcrumbData?.length) {
      const categoryName = breadcrumbData[breadcrumbData.length - 1].label;

      return t("productListing.productHeadline", {
        categoryName,
        totalAmountOfProducts,
        numberOfFirstProduct,
        numberOfLastProduct,
      });
    }

    if (searchTerm) {
      if (searchOrderset) {
        return t("search.searchResultHeadlineCatalog", {
          searchTerm,
          totalAmountOfProducts,
          numberOfFirstProduct,
          numberOfLastProduct,
        });
      }
      return t("search.searchResultHeadline", {
        searchTerm,
        totalAmountOfProducts,
        numberOfFirstProduct,
        numberOfLastProduct,
      });
    }

    return t("search.generalSearchResult", {
      totalAmountOfProducts,
      numberOfFirstProduct,
      numberOfLastProduct,
    });
  }, [
    breadcrumbData,
    customerProductLists,
    overrideHeadline,
    page,
    pageSize,
    productListId,
    searchOrderset,
    searchTerm,
    t,
    totalAmountOfProducts,
  ]);
};

export default useHeadline;
