import { RootReducerState } from "../reducers";
import {
  CustomerProductlist,
  CUSTOMER_PRODUCTLIST_TYPE_ORDERSET,
  CUSTOMER_PRODUCTLIST_TYPE_LABELLIST,
  CUSTOMER_PRODUCTLIST_TYPE_REQUISITION_LIST,
  CUSTOMER_PRODUCTLIST_TYPE_STORAGE_LIST,
  CUSTOMER_PRODUCTLIST_TYPE_CONTRACT,
  CUSTOMER_PRODUCTLIST_TYPE_OFFER,
} from "types/CustomerProductlist";
import { OrderHistory } from "types/Order";
import { pageSizeOptions, defaultPageSize } from "constants/site";
import { createSelector } from "@reduxjs/toolkit";

const EMPTY_OBJECT = {};
const EMPTY_ARRAY = [];

export const loginStatusSelector = (state: RootReducerState) =>
  state.user.userLoggedIn ?? false;

export const trackingChoiceSelector = (state: RootReducerState) =>
  state.user.trackingChoice;

export const loginErrorMsgSelector = (state: RootReducerState) =>
  state.user.userLoginErrorMsg;

export const guestStatusSelector = (state: RootReducerState) =>
  state.user.isGuest ?? false;

export const wcTokenSelector = (state: RootReducerState) => state.user.WCToken;

export const wcTrustedTokenSelector = (state: RootReducerState) =>
  state.user.WCTrustedToken;

export const userIdSelector = (state: RootReducerState) => state.user.userId;

export const logonIdSelector = (state: RootReducerState) =>
  state.user.details?.logonId || "";

export const disponentSelector = (state: RootReducerState) =>
  state.user.disponent || false;

export const userPageSizeSelector = (state: RootReducerState) => {
  const field1 = state.user.details?.attributes.find(
    (item) => item.pProfileAttrKey === "userProfileField1"
  )?.pProfileAttrValue;
  return pageSizeOptions.includes(field1) ? field1 : defaultPageSize;
};

export const userInitStatusSelector = (state: RootReducerState) =>
  state.user.initiatedFromStorage;

export const userLastUpdatedSelector = (state: RootReducerState) => {
  return state.user.lastUpdated;
};

export const registrationStatusSelector = (state: RootReducerState) =>
  state.user.userRegistration || false;

export const userNewsletterValidationCodeSelector = (state: RootReducerState) =>
  state.user.newsletterValidationCode || "";

export const userNextDeliveryDate = (state: RootReducerState) =>
  state.user.nextDeliveryDate.date || null;

export const userStockDeliveryDateDate = (state: RootReducerState) =>
  state.user.stockDeliveryDate?.date
    ? state.user.stockDeliveryDate.date
    : undefined;

export const userStockDeliveryDate = (state: RootReducerState) =>
  state.user.stockDeliveryDate;

export const userDeliveryDates = (state: RootReducerState) =>
  state.user.deliveryDates ?? EMPTY_ARRAY;

export const userDetailsSelector = (state: RootReducerState) =>
  state.user.details;

export const userCustomerNumberSelector = (state: RootReducerState) =>
  state.user.details?.customer?.number ?? "";

export const userPersonalizationIdSelector = (state: RootReducerState) =>
  state.user?.personalizationID ?? "";

export const customerConfigSelector = (state: RootReducerState) =>
  state.user.details?.customer?.config ?? EMPTY_OBJECT;

export const userConfigSelector = (state: RootReducerState) =>
  state.user.details?.config ?? EMPTY_OBJECT;

export const userIsCentralPurchaserSelector = (state: RootReducerState) =>
  state.user.centralPurchaser ?? false;

export const userEntitledOrganizationsSelector = (state: RootReducerState) =>
  state.user.entitledOrganizations ?? EMPTY_ARRAY;

export const userOrgIdSelector = (state: RootReducerState) =>
  state.user.details?.organizationId ?? "";

export const userActiveOrgIdSelector = (state: RootReducerState) =>
  state.user.details?.activeOrganizationId ?? "";

export const userCentralPurchaserSelector = (state: RootReducerState) => ({
  centralPurchaser: userIsCentralPurchaserSelector(state),
  entitledOrganizations: userEntitledOrganizationsSelector(state),
  activeOrganizationId: userActiveOrgIdSelector(state),
  organizationId: userOrgIdSelector(state),
});

export const currentOrganizationIdSelector = (
  state: RootReducerState
): string =>
  state.user.centralPurchaser
    ? state.user.details?.activeOrganizationId ?? ""
    : state.user.details?.organizationId ?? "";

export const customerContracts = (state: RootReducerState) =>
  state.user.contracts || EMPTY_ARRAY;

export const hasCustomerProductLists = (state: RootReducerState) =>
  !!state.user.customerProductLists?.length;

const selectCustomerProductLists = (state: RootReducerState) =>
  state.user.customerProductLists;

export const selectSortedCustomerProductLists = createSelector(
  [selectCustomerProductLists],
  (customerProductLists) => {
    if (!customerProductLists?.length) {
      return [];
    }

    const result: CustomerProductlist[] = [];

    const orderset = customerProductLists.find(
      (customerProductList) =>
        customerProductList.type === CUSTOMER_PRODUCTLIST_TYPE_ORDERSET
    );
    if (orderset) {
      result.push(orderset);
    }

    const labelList = customerProductLists.find(
      (customerProductList) =>
        customerProductList.type === CUSTOMER_PRODUCTLIST_TYPE_LABELLIST
    );
    if (labelList) {
      result.push(labelList);
    }

    result.push(
      ...customerProductLists.filter(
        (customerProductList) =>
          customerProductList.type ===
            CUSTOMER_PRODUCTLIST_TYPE_REQUISITION_LIST ||
          customerProductList.type === CUSTOMER_PRODUCTLIST_TYPE_STORAGE_LIST
      )
    );

    return result;
  }
);

export const selectCustomerOrderSet = createSelector(
  [selectCustomerProductLists],
  (customerProductLists) =>
    customerProductLists
      ? customerProductLists.find(
          (item) => item.type === CUSTOMER_PRODUCTLIST_TYPE_ORDERSET
        )
      : undefined
);

export const customerProductListsRaw = (state: RootReducerState) =>
  state.user.customerProductLists ?? EMPTY_ARRAY;

export const userSelectedProductlistIdSelector = (state: RootReducerState) =>
  state.user.selectedProductListId;

export const userSelectedProductlistFilterSelector = (
  state: RootReducerState
) => state.user.selectedProductListFilter ?? EMPTY_ARRAY;

export const userContactPersonState = (state: RootReducerState) =>
  state.user.details?.contactPerson || null;

export const customerOffers = (state: RootReducerState) =>
  state.user.offers || EMPTY_ARRAY;

export const customerEFoodContracts = (state: RootReducerState) =>
  state.user.efoodContracts || EMPTY_ARRAY;

export const customerAssignedProductLists = (state: RootReducerState) =>
  state.user.assignedProductLists || EMPTY_ARRAY;

const DEFAULT_ORDER_HISTORY = { entries: [], size: 0 };
export const userOrderHistorySelector = (
  state: RootReducerState
): OrderHistory => state.user.orderHistory || DEFAULT_ORDER_HISTORY;

export const customerProductListsWithContractsSelector = (
  state: RootReducerState
): CustomerProductlist[] => {
  const myArticleLists: CustomerProductlist[] = [];

  if (state.user.customerProductLists?.length) {
    const orderset = state.user.customerProductLists.find(
      (customerProductList) =>
        customerProductList.type === CUSTOMER_PRODUCTLIST_TYPE_ORDERSET
    );
    if (orderset) {
      myArticleLists.push(orderset);
    }

    const labelList = state.user.customerProductLists.find(
      (customerProductList) =>
        customerProductList.type === CUSTOMER_PRODUCTLIST_TYPE_LABELLIST
    );
    if (labelList) {
      myArticleLists.push(labelList);
    }
  }

  if (state.user.efoodContracts?.length) {
    if (!state.user.assignedProductLists) {
      state.user.efoodContracts.forEach((contract) => {
        myArticleLists.push({
          id: contract.id,
          name: contract.title,
          type: CUSTOMER_PRODUCTLIST_TYPE_CONTRACT,
        });
      });
    } else {
      state.user.assignedProductLists.forEach((contractId) => {
        const name = state.user.efoodContracts.find(
          (item) => item.id === contractId
        );
        if (name && name.title)
          myArticleLists.push({
            id: contractId,
            name: name.title,
            type: CUSTOMER_PRODUCTLIST_TYPE_CONTRACT,
          });
      });
    }
  }

  if (state.user.customerProductLists?.length) {
    myArticleLists.push(
      ...state.user.customerProductLists.filter(
        (customerProductList) =>
          customerProductList.type ===
            CUSTOMER_PRODUCTLIST_TYPE_REQUISITION_LIST ||
          customerProductList.type === CUSTOMER_PRODUCTLIST_TYPE_STORAGE_LIST
      )
    );
  }

  return myArticleLists;
};

export const customerProductListsWithContractsAndOffersSelector = (
  state: RootReducerState
): CustomerProductlist[] => {
  const productLists: CustomerProductlist[] =
    customerProductListsWithContractsSelector(state);

  if (state.user.offers) {
    state.user.offers.forEach((offer) => {
      productLists.push({
        id: offer.id,
        name: offer.title,
        type: CUSTOMER_PRODUCTLIST_TYPE_OFFER,
      });
    });
  }

  return productLists;
};

export const userLastLocationChangeSelector = (state: RootReducerState) =>
  state.user.lastLocationChange || "";

export const userGetPriceChanges = (state: RootReducerState) =>
  state.user.priceChanges || null;

export const rewardOptionIdsHandledSelector = (state: RootReducerState) =>
  state.user.rewardOptionIdsHandled;

export const orderItemsWithOrderSplitSelector = (state: RootReducerState) =>
  state.user.orderItemsWithOrderSplit;

export const basketPredictionSelector = (state: RootReducerState) =>
  state.user.prediction || null;
