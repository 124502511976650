import { sortProductLists } from "components/helpers/sortProductLists";
import { useMemo } from "react";
import {
  customerAssignedProductLists,
  customerEFoodContracts,
  customerProductListsRaw,
} from "_redux/selectors/user";
import { useAppSelector } from "_redux/hooks";
import {
  CustomerProductlist,
  CUSTOMER_PRODUCTLIST_TYPE_CONTRACT,
  CUSTOMER_PRODUCTLIST_TYPE_LABELLIST,
  CUSTOMER_PRODUCTLIST_TYPE_ORDERSET,
  CUSTOMER_PRODUCTLIST_TYPE_REQUISITION_LIST,
  CUSTOMER_PRODUCTLIST_TYPE_STORAGE_LIST,
} from "types/CustomerProductlist";

const useCustomerProductListsWithContracts = (sort = false) => {
  const rawCustomerProductLists = useAppSelector(customerProductListsRaw);
  const efoodContracts = useAppSelector(customerEFoodContracts);
  const assignedProductLists = useAppSelector(customerAssignedProductLists);

  return useMemo(() => {
    const customerProductLists: CustomerProductlist[] = [];

    const orderset = rawCustomerProductLists.find(
      (customerProductList) =>
        customerProductList.type === CUSTOMER_PRODUCTLIST_TYPE_ORDERSET
    );
    if (orderset) {
      customerProductLists.push(orderset);
    }

    const labelList = rawCustomerProductLists.find(
      (customerProductList) =>
        customerProductList.type === CUSTOMER_PRODUCTLIST_TYPE_LABELLIST
    );
    if (labelList) {
      customerProductLists.push(labelList);
    }

    if (efoodContracts?.length) {
      if (!assignedProductLists?.length) {
        efoodContracts.forEach((contract) => {
          customerProductLists.push({
            id: contract.id,
            name: contract.title,
            type: CUSTOMER_PRODUCTLIST_TYPE_CONTRACT,
          });
        });
      } else {
        assignedProductLists.forEach((contractId) => {
          const name = efoodContracts.find((item) => item.id === contractId);
          if (name && name.title)
            customerProductLists.push({
              id: contractId,
              name: name.title,
              type: CUSTOMER_PRODUCTLIST_TYPE_CONTRACT,
            });
        });
      }
    }

    const requistionAndStorageLists = rawCustomerProductLists.filter(
      (customerProductList) =>
        customerProductList.type ===
          CUSTOMER_PRODUCTLIST_TYPE_REQUISITION_LIST ||
        customerProductList.type === CUSTOMER_PRODUCTLIST_TYPE_STORAGE_LIST
    );
    if (requistionAndStorageLists.length) {
      customerProductLists.push(...requistionAndStorageLists);
    }

    if (sort) {
      sortProductLists(customerProductLists);
    }

    return customerProductLists;
  }, [assignedProductLists, efoodContracts, rawCustomerProductLists]);
};

export default useCustomerProductListsWithContracts;
