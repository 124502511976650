import React, { FC, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { PredictionList } from "components/prediction";
import { StyledKeyVisual } from "components/StyledUI";
import { Typography } from "@mui/material";
import pbImage from "assets/images/pb-banner.jpg";
import { useAppSelector } from "_redux/hooks";
import {
  basketPredictionSelector,
  userActiveOrgIdSelector,
} from "_redux/selectors/user";
import { tss } from "tss-react/mui";

const useStyles = tss.create(({ theme }) => ({
  root: {
    [theme.breakpoints.down("sm")]: {
      fontSize: "26px !important",
    },
  },
}));

const Prediction: FC = () => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const prediction = useAppSelector(basketPredictionSelector);
  const [lastActiveOrgId, setLastActiveOrgId] = useState("");
  const [reloadPredictiveBasket, setReloadPredictiveBasket] = useState(false);

  const activeOrgId = useAppSelector(userActiveOrgIdSelector);

  useEffect(() => {
    if (lastActiveOrgId !== activeOrgId) {
      setLastActiveOrgId(activeOrgId);
      setReloadPredictiveBasket(true);
    } else {
      setReloadPredictiveBasket(false);
    }
  }, [activeOrgId, lastActiveOrgId]);

  return (
    <>
      <Helmet>
        <title>
          {process.env.REACT_APP_STORENAME} - {t("prediction.pageTitle")}
        </title>
      </Helmet>
      <StyledKeyVisual
        imageSrc={pbImage}
        imageAlt={t("prediction.bannerTitle")}>
        <Typography className={classes.root}>
          {t("prediction.bannerTitle1")}
          <br />
          {t("prediction.bannerTitle2")}
        </Typography>
      </StyledKeyVisual>
      {prediction && (
        <PredictionList reloadPredictiveBasket={reloadPredictiveBasket} />
      )}
    </>
  );
};

export default Prediction;
