import { createAction } from "@reduxjs/toolkit";
import { UserReducerState } from "_redux/reducers";
import { DeliveryDate } from "types/Order";
import * as ACTIONTYPES from "../action-types/user";
import { NavigateFunction } from "react-router-dom";

export const RENEW_LOGIN_REQUESTED_ACTION = createAction<
  {
    requestPayload: {
      body: { refreshToken?: string; rememberMe?: boolean };
      query?: { rememberMe?: boolean; oldUser?: Partial<UserReducerState> };
      [key: string]: unknown;
    };
    resolve?: (value?: unknown) => void;
    reject?: (reason?: unknown) => void;
  },
  typeof ACTIONTYPES.RENEW_LOGIN_REQUESTED
>(ACTIONTYPES.RENEW_LOGIN_REQUESTED);

export const REDIRECTED_LOGIN_REQUESTED_ACTION = createAction<
  any,
  typeof ACTIONTYPES.REDIRECTED_LOGIN_REQUESTED
>(ACTIONTYPES.REDIRECTED_LOGIN_REQUESTED);

export const LOGIN_REQUESTED_ACTION = createAction<any, string>(
  ACTIONTYPES.LOGIN_REQUESTED
);

export const LOGOUT_REQUESTED_ACTION = createAction<{
  navigate?: NavigateFunction;
}>(ACTIONTYPES.LOGOUT_REQUESTED);

export const LOGIN_SUCCESS_ACTION = createAction<any, string>(
  ACTIONTYPES.LOGIN_SUCCESS
);

export const SESSION_ERROR_LOGIN_REQUESTED_ACTION = createAction<any, string>(
  ACTIONTYPES.SESSION_ERROR_LOGIN_REQUESTED
);

export const SESSION_ERROR_LOGIN_ERROR_ACTION = createAction<any, string>(
  ACTIONTYPES.SESSION_ERROR_LOGIN_ERROR
);

export const LOGOUT_SUCCESS_ACTION = createAction(ACTIONTYPES.LOGOUT_SUCCESS);

export const loginErrorAction = createAction<
  any,
  typeof ACTIONTYPES.LOGIN_ERROR
>(ACTIONTYPES.LOGIN_ERROR);

export const registrationAction = createAction<any, string>(
  ACTIONTYPES.REGISTRATION_REQUESTED
);

export const REGISTRATION_SUCCESS_ACTION = createAction<any, string>(
  ACTIONTYPES.REGISTRATION_SUCCESS
);

export const registrationErrorAction = createAction<any, string>(
  ACTIONTYPES.REGISTRATION_ERROR
);

export const NEWSLETTER_SUCCESS_ACTION = createAction<any, string>(
  ACTIONTYPES.NEWSLETTER_SUCCESS
);

export const NEWSLETTER_ERROR_ACTION = createAction<any, string>(
  ACTIONTYPES.NEWSLETTER_ERROR
);

export const INIT_USER_FROM_STORAGE_SUCCESS_ACTION = createAction<any, string>(
  ACTIONTYPES.INIT_USER_FROM_STORAGE_SUCCESS
);

export const INIT_STATE_FROM_STORAGE_ACTION = createAction(
  ACTIONTYPES.INIT_STATE_FROM_STORAGE
);

export const FETCH_USER_DETAILS_SUCCESS_ACTION = createAction<any, string>(
  ACTIONTYPES.FETCH_USER_DETAILS_SUCCESS
);

export const FETCH_CONTRACTS_SUCCESS_ACTION = createAction<any, string>(
  ACTIONTYPES.FETCH_CONTRACTS
);

export const FETCH_DELIVERY_DATES_SUCCESS_ACTION = createAction<
  DeliveryDate[],
  typeof ACTIONTYPES.FETCH_DELIVERY_DATES_SUCCESS
>(ACTIONTYPES.FETCH_DELIVERY_DATES_SUCCESS);

export const FETCH_DELIVERY_DATE_SUCCESS_ACTION = createAction<
  DeliveryDate,
  typeof ACTIONTYPES.FETCH_DELIVERY_DATE_SUCCESS
>(ACTIONTYPES.FETCH_DELIVERY_DATE_SUCCESS);

export const UPDATE_DELIVERY_DATE_REQUEST_ACTION = createAction<any, string>(
  ACTIONTYPES.UPDATE_DELIVERY_DATE_REQUESTED
);

export const FETCH_CUSTOMER_PRODUCTLISTS_ACTION = createAction<any, string>(
  ACTIONTYPES.FETCH_CUSTOMER_PRODUCTLISTS_REQUESTED
);

export const RE_FETCH_CUSTOMER_PRODUCTLISTS_ACTION = createAction(
  ACTIONTYPES.FETCH_CUSTOMER_PRODUCTLISTS
);

export const RELOAD_CUSTOMER_DATA_ACTION = createAction<any, string>(
  ACTIONTYPES.RELOAD_CUSTOMER_DATA
);

export const RELOAD_PERSON_DATA_ACTION = createAction<any, string>(
  ACTIONTYPES.RELOAD_PERSON_DATA
);

export const FETCH_ENTITLED_ORGANIZATIONS_ACTION = createAction<any, string>(
  ACTIONTYPES.FETCH_ENTITLED_ORGANIZATIONS
);

export const FETCH_OFFERS_SUCCESS_ACTION = createAction<any, string>(
  ACTIONTYPES.FETCH_OFFERS
);

export const FETCH_EFOOD_CONTRACTS_SUCCESS_ACTION = createAction<any, string>(
  ACTIONTYPES.FETCH_EFOOD_CONTRACTS
);

export const FETCH_ORDER_HISTORY_ACTION = createAction<any, string>(
  ACTIONTYPES.FETCH_ORDER_HISTORY
);

export const PUT_SELECTED_PRODUCTLIST = createAction<any, string>(
  ACTIONTYPES.PUT_SELECTED_PRODUCTLIST
);

export const RELOAD_ORDER_HISTORY_ACTION = createAction<any, string>(
  ACTIONTYPES.RELOAD_ORDER_HISTORY
);

export const UPDATE_PRODUCTLIST_FILTER = createAction<any, string>(
  ACTIONTYPES.UPDATE_PRODUCTLIST_FILTER
);

export const SET_TRACKING_CHOICE_ACTION = createAction<string, string>(
  ACTIONTYPES.SET_TRACKING_CHOICE
);

export const ACCEPT_PRICE_CHANGES_ACTION = createAction<any, string>(
  ACTIONTYPES.ACCEPT_PRICE_CHANGES
);

export const SET_STORAGE_LOCATION_CHANGED = createAction(
  ACTIONTYPES.SET_STORAGE_LOCATION_CHANGED
);

export const UPDATE_DELIVERY_DATE_SUCCESS_ACTION = createAction(
  ACTIONTYPES.UPDATE_DELIVERY_DATE_SUCCESS
);

export const REWARDS_HANDLED_ACTION = createAction<any, string>(
  ACTIONTYPES.REWARDS_HANDLED
);

export const SET_ORDER_SPLIT_ITEMS_ACTION = createAction<any, string>(
  ACTIONTYPES.SET_ORDER_SPLIT_ITEMS
);

export const ORDER_SPLIT_HINT_VIEWED_ACTION = createAction(
  ACTIONTYPES.ORDER_SPLIT_HINT_VIEWED
);

export const PREDICTIVE_BASKET_ACTION = createAction<any, string>(
  ACTIONTYPES.PREDICTIVE_BASKET_REQUESTED
);

export const FETCH_DELIVERY_DATES_REQUESTED_ACTION = createAction<any, string>(
  ACTIONTYPES.FETCH_DELIVERY_DATES_REQUESTED
);
