import React, { FC, useState, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { useAppDispatch } from "_redux/hooks";
import { FETCHING_CART_ACTION } from "_redux/actions/order";

import {
  useOnlineStatus,
  useSite,
  useSortedCustomerProductList,
} from "_foundationExt/hooks";
import customerProductlistService from "_foundationExt/apis/transaction/customerProductlist.service";

import { Menu, MenuItem, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { StyledButton } from "components/StyledUI";

import {
  CUSTOMER_PRODUCTLIST_TYPE_REQUISITION_LIST,
  CUSTOMER_PRODUCTLIST_TYPE_STORAGE_LIST,
} from "types/CustomerProductlist";
import CreateSaveDialog from "./CreateSaveDialog";

const SaveInListButton: FC = () => {
  const { currentSite } = useSite();
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const breakpointDownXs = useMediaQuery(theme.breakpoints.down("sm"));
  const { t } = useTranslation();
  const { isOffline } = useOnlineStatus();

  const customerProductLists = useSortedCustomerProductList();

  const [createdList, setCreatedList] = useState("");
  const [showSaveInNewListDialog, setShowSaveInNewListDialog] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const saveInList = useCallback(
    (customerProductListId) => {
      if (currentSite?.storeID) {
        customerProductlistService
          .addProduct({
            storeId: currentSite.storeID,
            customerProductListId,
            body: {
              productId: "cart",
            },
          })
          .then(() => dispatch(FETCHING_CART_ACTION({})));
      }
    },
    [currentSite?.storeID, dispatch]
  );

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (createdList) {
      const createdProductList = customerProductLists.find(
        (list) => list.name === createdList
      );

      if (createdProductList) {
        saveInList(createdProductList.id);
        setCreatedList("");
      }
    }
  }, [createdList, customerProductLists, saveInList]);

  return (
    <>
      <StyledButton
        variant="outlined"
        fullWidth={breakpointDownXs}
        onClick={(event) => {
          setAnchorEl(event.currentTarget);
        }}
        disabled={isOffline}>
        {t("cart.saveInList")}
      </StyledButton>

      <Menu
        id="save-in-list-menu"
        anchorEl={anchorEl}
        keepMounted
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={Boolean(anchorEl)}
        onClose={handleClose}>
        {customerProductLists.map(
          (articleList) =>
            (articleList.type === CUSTOMER_PRODUCTLIST_TYPE_REQUISITION_LIST ||
              articleList.type === CUSTOMER_PRODUCTLIST_TYPE_STORAGE_LIST) && (
              <MenuItem
                key={articleList.id}
                onClick={() => {
                  saveInList(articleList.id);
                  handleClose();
                }}>
                {articleList.name}
              </MenuItem>
            )
        )}

        <MenuItem
          onClick={() => {
            setShowSaveInNewListDialog(true);
            handleClose();
          }}>
          {t("cart.modalSaveInList.createNewList")}
        </MenuItem>
      </Menu>

      <CreateSaveDialog
        closeDialog={() => setShowSaveInNewListDialog(false)}
        showDialog={showSaveInNewListDialog}
      />
    </>
  );
};

export default SaveInListButton;
