/* eslint-disable no-param-reassign */
// Standard libraries
import axios, {
  AxiosRequestConfig,
  AxiosResponse,
  RawAxiosRequestHeaders,
  InternalAxiosRequestConfig,
  AxiosError,
  isAxiosError,
  HttpStatusCode,
} from "axios";
import i18n from "i18next";
import { parse as losslessParse, isLosslessNumber } from "lossless-json";
import { StatusCodes } from "http-status-codes";
import { pascalCase } from "change-case";
// Foundation libraries
import {
  axiosESIgnoredService,
  axiosHeaderIgnoredServices,
} from "_foundation/configs/axiosHeaderIgnoredService";
import { numberParserRequiredServices } from "_foundation/configs/numberParserRequiredService";
import {
  WC_PREVIEW_TOKEN,
  LANGID,
  FOR_USER_ID,
  SKIP_WC_TOKEN_HEADER,
  PRODUCTION,
  SHOW_API_FLOW,
  SELLER_PARAM,
} from "_foundation/constants/common";
import { site } from "_foundationExt/constants/site";
import { PERSONALIZATION_ID } from "constants/user";
import { ASSETS_PATH } from "_foundation/constants/assets";
import { PrerenderTimer } from "_foundation/utils/prerenderTimer";
import {
  localStorageUtil,
  storageSessionHandler,
  storageStoreIdHandler,
} from "_foundation/utils/storageUtil";
// Redux
import { Dispatch } from "@reduxjs/toolkit";
import { WATCH_AXIOS_ERROR_ACTION } from "_redux/actions/error";
// import { GUEST_LOGIN_SUCCESS_ACTION } from "redux/actions/user";
import { API_CALL_ACTION } from "_redux/actions/api";

// Custom libraries
import { RENEW_LOGIN_REQUESTED_ACTION } from "_redux/actions/user";
import { UserReducerState } from "_redux/reducers";
import { AUTHENTICATION_ERROR_GENERIC_USER_NOT_ALLOWED } from "constants/errors";
import isInstanaActive from "tools/isInstanaActive";
import { useLayoutEffect } from "react";
import { useAppDispatch } from "_redux/hooks";
import {
  CommerceEnvironment,
  SELLER_STORAGE_KEY,
} from "../../constants/common";

// const GUEST_IDENTITY = "guestidentity";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isESSvcInList = (req: AxiosRequestConfig, list: any) => {
  const { url = "" } = req;
  // eslint-disable-next-line no-underscore-dangle
  const _list = Object.keys(list);
  if (url) {
    const path = `${site.searchContext}/`;
    const search = url.split("?")[0].split(path).pop();
    // can do some extra work here to replace store-ids in the list, but right
    //   now we have no such services to be filtered (that use store-id)
    return search && _list.indexOf(search) >= 0;
  }
  return false;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isServiceInList = (request: AxiosRequestConfig, serviceList: any) => {
  const { url = "", method } = request;
  if (url) {
    // eslint-disable-next-line no-underscore-dangle
    const _method = method?.toUpperCase();
    // eslint-disable-next-line no-underscore-dangle
    const _serviceList = Object.keys(serviceList);

    const storePath = `${site.transactionContext}/store/`;
    const path = url.split("?")[0].split(storePath).pop();
    if (path) {
      const serviceName = path.split("/")[1];
      return _serviceList.some((s) => {
        const { skip = "" } = serviceList[s];
        return s === serviceName && skip !== _method;
      });
    }
  }
  return false;
};

const isNumberParserRequiredService = (request: AxiosRequestConfig) =>
  isServiceInList(request, numberParserRequiredServices);

const dispatchHolder: {
  dispatch?: Dispatch;
} = {};

const processTransactionHeader = (header?: RawAxiosRequestHeaders) => {
  if (!header) {
    return;
  }
  const personalizationIdManagedByCookie = !(
    process.env.REACT_APP_PERSISTENT_SESSION?.toLowerCase() === "true"
  );

  const currentUser = storageSessionHandler.getCurrentUserAndLoadAccount();

  if (currentUser) {
    if (!header.WCTrustedToken && currentUser.WCTrustedToken) {
      header.WCTrustedToken = currentUser.WCTrustedToken;
    }
    if (!header.WCToken && currentUser.WCToken) {
      header.WCToken = currentUser.WCToken;
    }

    if (personalizationIdManagedByCookie) {
      if (!header.WCPersonalization && currentUser.WCPersonalization) {
        header.WCPersonalization = currentUser.personalizationID;
      }
    }
  }

  // personalization id managed by persistent cookie, "true" to skip this.
  if (personalizationIdManagedByCookie && !header.WCPersonalization) {
    const personalizationID = localStorageUtil.get(PERSONALIZATION_ID);
    if (personalizationID != null) {
      header.WCPersonalization = personalizationID;
    }
  }

  const previewToken = storageSessionHandler.getPreviewToken();
  if (previewToken && previewToken[WC_PREVIEW_TOKEN]) {
    header.WCPreviewToken = previewToken[WC_PREVIEW_TOKEN];
  }

  header["Cache-Control"] = "no-cache";
  header.Pragma = "no-cache";
};

const processSearchHeader = (header?: RawAxiosRequestHeaders) => {
  if (!header) {
    return;
  }

  const currentUser = storageSessionHandler.getCurrentUserAndLoadAccount();

  if (currentUser) {
    if (!header.WCTrustedToken && currentUser.WCTrustedToken) {
      header.WCTrustedToken = currentUser.WCTrustedToken;
    }
    if (!header.WCToken && currentUser.WCToken) {
      header.WCToken = currentUser.WCToken;
    }
  }

  const previewToken = storageSessionHandler.getPreviewToken();

  if (previewToken && previewToken[WC_PREVIEW_TOKEN]) {
    header.WCPreviewToken = previewToken[WC_PREVIEW_TOKEN];
  }

  header["Cache-Control"] = "no-cache";
  header.Pragma = "no-cache";
};

const transformResponse = (data: unknown) => {
  let result = data;
  const previewToken = storageSessionHandler.getPreviewToken();
  if (typeof data === "string") {
    result = losslessParse(data, (key, value) => {
      // transform number
      if (isLosslessNumber(value)) {
        return value.toString();
      }
      if (previewToken && previewToken[WC_PREVIEW_TOKEN]) {
        // handle preview  asset
        const token = previewToken[WC_PREVIEW_TOKEN];
        if (
          ASSETS_PATH.includes(key.trim()) &&
          typeof value === "string" &&
          value.toLocaleLowerCase().indexOf("http") !== 0
        ) {
          if (value.indexOf("?") > -1) {
            return `${value}&${WC_PREVIEW_TOKEN}=${token}`;
          }
          return `${value}?${WC_PREVIEW_TOKEN}=${token}`;
        }
      }
      return value;
    });
  }
  return result;
};
const isUseSnackbarHandleError = (error: AxiosError) => {
  if (error.config) {
    const { skipErrorSnackbar } = error.config as {
      skipErrorSnackbar?: boolean;
    };
    if (skipErrorSnackbar) {
      return false;
    }
  }
  return !(
    isAxiosError(error) && error.response?.status === StatusCodes.NOT_FOUND
  );
};

const showAPIFlow = (
  method?: string,
  requestUrl?: string,
  widget = "Browser"
) => {
  if (process.env.NODE_ENV === PRODUCTION) {
    return;
  }

  const managedServer = (() => {
    if (!requestUrl) {
      return false;
    }
    if (requestUrl.startsWith(site.legacyContext)) {
      return "LEGACY";
    }
    if (requestUrl.startsWith(site.transactionContext)) {
      return "TRANSACTION";
    }
    if (requestUrl.startsWith(site.searchContext)) {
      return "SEARCH";
    }
    if (requestUrl.startsWith(site.dxContext)) {
      return "DX";
    }
    return false;
  })();

  const isShowAPIFlow =
    localStorageUtil.get(SHOW_API_FLOW) === "true" && managedServer;
  if (isShowAPIFlow) {
    const { dispatch } = dispatchHolder;
    if (dispatch) {
      dispatch(
        API_CALL_ACTION(
          `${pascalCase(
            String(widget)
          )} -> ${managedServer}: ${method} ${requestUrl}`
        )
      );
    }
  }
};

const getUrlParamsSeparator = (url: string) =>
  url.indexOf("?") === -1 ? "?" : "&";

const processForUserParameter = (request: InternalAxiosRequestConfig) => {
  const currentUser = storageSessionHandler.getCurrentUserAndLoadAccount();
  const { url, params } = request;
  if (
    currentUser &&
    currentUser.forUserId &&
    url &&
    url.indexOf(FOR_USER_ID) === -1
  ) {
    const separator = getUrlParamsSeparator(url);
    request.url = `${url}${separator}${FOR_USER_ID}=${currentUser.forUserId}`;
  }

  if (
    currentUser?.WCToken &&
    currentUser?.contracts &&
    (url?.startsWith(site.searchContext) ||
      url?.includes(site.searchContextEfood))
  ) {
    Object.keys(currentUser.contracts).forEach((contractId) => {
      params.set("contractId", contractId);
    });
  }
};

const processLangIdParameter = (request: InternalAxiosRequestConfig) => {
  const { url } = request;
  if (
    url &&
    !isServiceInList(request, axiosHeaderIgnoredServices) &&
    !isESSvcInList(request, axiosESIgnoredService) &&
    url.indexOf(LANGID) === -1
  ) {
    const langId =
      CommerceEnvironment.reverseLanguageMap[
        i18n.languages[0].split("-").join("_")
      ];
    const separator = getUrlParamsSeparator(url);
    request.url = `${url}${separator}${LANGID}=${langId}`;
  }
};

const processSetSeller = (request: InternalAxiosRequestConfig) => {
  const sellers = localStorageUtil.get(SELLER_STORAGE_KEY);
  const { url = "" } = request;
  const RE = new RegExp(`\\b${SELLER_PARAM}=`);

  if (
    url &&
    sellers?.length &&
    url.indexOf(`${site.searchContext}/`) !== -1 &&
    !RE.test(url) &&
    !isESSvcInList(request, axiosESIgnoredService)
  ) {
    const separator = getUrlParamsSeparator(url);
    const values = sellers.map((s) => `${SELLER_PARAM}=${s}`).join("&");
    request.url = `${url}${separator}${values}`;
  }
};

const processHeaders = (request: InternalAxiosRequestConfig) => {
  if (request.url?.startsWith(site.legacyContext)) {
    const header = request.headers;
    processTransactionHeader(header);
    return;
  }

  if (request.url?.startsWith(site.transactionContext)) {
    if (
      !isServiceInList(request, axiosHeaderIgnoredServices) &&
      !request[SKIP_WC_TOKEN_HEADER]
    ) {
      const header = request.headers;
      processTransactionHeader(header);
    }
    return;
  }

  if (request.url?.startsWith(site.searchContext)) {
    const header = request.headers;
    processSearchHeader(header);
  }
};

const processAuthErrorState = {
  isRenewing: false,
  failedRequests: [] as Array<{
    resolve: (value?: unknown) => void;
    reject: (reason?: unknown) => void;
  }>,
  processFailedRequests: function processFailedRequests(error?: unknown) {
    this.failedRequests.forEach((failedRequest) => {
      if (error) {
        failedRequest.reject(error);
      } else {
        failedRequest.resolve();
      }
    });
    this.failedRequests = [];
  },
};

const isCanceledError = (error: unknown) => axios.isCancel(error);

const isAuthError = (error: unknown) =>
  isAxiosError(error) &&
  error.config &&
  (error.response?.status === HttpStatusCode.Unauthorized ||
    (error.response?.status === HttpStatusCode.Forbidden &&
      error.response?.data?.errors?.some(
        (e: { errorKey?: string; errorParameters?: string[] }) =>
          e.errorKey === "NOT_AUTHORIZED_FOR_QUERY" &&
          Array.isArray(e.errorParameters) &&
          e.errorParameters.find((v) => v.endsWith("OrderHistoryListDataBean"))
      )) ||
    (error.response?.status === HttpStatusCode.BadRequest &&
      error.response?.data?.errors?.some(
        (e: { errorCode?: string; errorKey?: string }) =>
          e.errorCode === AUTHENTICATION_ERROR_GENERIC_USER_NOT_ALLOWED ||
          e.errorKey === AUTHENTICATION_ERROR_GENERIC_USER_NOT_ALLOWED
      )));

const processAuthError = async (error: AxiosError) => {
  const { dispatch } = dispatchHolder;

  if (isAuthError(error)) {
    const {
      isRetryRequest = false,
      headers,
      ...rest
    } = error.config as InternalAxiosRequestConfig & {
      isRetryRequest?: boolean;
    };
    const { refreshToken } =
      (storageSessionHandler.getCurrentUserAndLoadAccount() as Partial<UserReducerState>) ??
      {};

    if (
      !isRetryRequest &&
      dispatch &&
      (processAuthErrorState.isRenewing || refreshToken)
    ) {
      if (processAuthErrorState.isRenewing) {
        try {
          // remove old authorization data
          delete headers?.WCTrustedToken;
          delete headers?.WCToken;
          // add request to failed requests and wait until relogon is finished
          await new Promise((resolve, reject) => {
            processAuthErrorState.failedRequests.push({ resolve, reject });
          });
          // relogon request was resolved so we can retry this failed request
          const config = { headers, ...rest };
          return axios.request(config);
        } catch (e) {
          // relogon request was rejected so we reject also this request
          if (isAxiosError(e) && e.code !== "ERR_CANCELED") {
            dispatch(WATCH_AXIOS_ERROR_ACTION(e));
          }
          return e;
        }
      }

      processAuthErrorState.isRenewing = true;
      const requestPayload = {
        body: {
          refreshToken,
          rememberMe: true,
        },
        query: {
          rememberMe: true,
        },
        widget: "axiosConfig",
      };
      const renewedUserPromise = new Promise((resolve, reject) => {
        dispatch(
          RENEW_LOGIN_REQUESTED_ACTION({
            requestPayload,
            resolve,
            reject,
          })
        );
      });

      return new Promise((resolve, reject) => {
        renewedUserPromise
          .then(() => {
            // relogon request resolved
            // remove old authorization data
            delete headers?.WCTrustedToken;
            delete headers?.WCToken;
            // retry first failed request
            const config = { headers, ...rest };
            resolve(axios.request(config));
            // retry all other failed requests
            processAuthErrorState.processFailedRequests();
          })
          .catch((e) => {
            // relogon request rejected, so fail this request and reject the other waiting requests
            if (isAxiosError(e) && e.code !== "ERR_CANCELED") {
              dispatch(WATCH_AXIOS_ERROR_ACTION(e));
            }
            processAuthErrorState.processFailedRequests(e);
            reject(e);
          })
          .finally(() => {
            // reset relogon in progress flag
            processAuthErrorState.isRenewing = false;
          });
      });
    }
  }
  if (isUseSnackbarHandleError(error) && error.code !== "ERR_CANCELED") {
    if (dispatch) {
      dispatch(WATCH_AXIOS_ERROR_ACTION(error));
    }
  }
  return Promise.reject(error);
};

/*
const processGuestPartialAuthError = async (error: AxiosError) => {
  const errorMessage = get(error, "response.data.errors[0]", {
    errorCode: undefined,
    errorKey: undefined,
  });
  const skipGuestPartialAuthError = get(
    error,
    "config.skipGuestPartialAuthError"
  );
  const { errorCode, errorKey } = errorMessage;

  let currentUser = storageSessionHandler.getCurrentUserAndLoadAccount();
  if (
    currentUser?.rememberMe &&
    currentUser?.isGuest &&
    (errorCode === ERRORS.PARTIAL_AUTHENTICATION_ERROR_CODE ||
      errorKey === ERRORS.PARTIAL_AUTHENTICATION_ERROR_KEY) &&
    !skipGuestPartialAuthError
  ) {
    const payload = {
      widget: "axiosConfig",
    };
    await guestIdentityService
      .login(payload)
      .then((resp: AxiosResponse) => {
        currentUser = resp.data;
        const { dispatch } = dispatchHolder;
        if (!dispatch) {
          return;
        }
        // guest shopper always remembered when persistent session enabled.
        const rememberMe =
          process.env.REACT_APP_PERSISTENT_SESSION?.toLowerCase() === "true"
            ? { rememberMe: true }
            : {};
        dispatch(
          GUEST_LOGIN_SUCCESS_ACTION({
            ...currentUser,
            ...payload,
            ...rememberMe,
          })
        );
      })
      .catch((err) => {
        throw err;
      });
    const config = { ...error.config, skipGuestPartialAuthError: true };
    const resp = await Axios.request(config);
    return resp;
  }
  if (isUseSnackbarHandleError(error) && error.code !== "ERR_CANCELED") {
    const { dispatch } = dispatchHolder;
    if (dispatch) {
      dispatch(WATCH_AXIOS_ERROR_ACTION(error));
    }
  }
  return Promise.reject(error);
};
*/

/*
const processGuestLogon = async (request: InternalAxiosRequestConfig) => {
  if (
    !request.url ||
    (request.url.indexOf(GUEST_IDENTITY) === -1 &&
      request.url.startsWith(site.transactionContext))
  ) {
    let currentUser = storageSessionHandler.getCurrentUserAndLoadAccount();
    if (!currentUser && isUserRequiredService(request)) {
      const payload = {
        widget: "axiosConfig",
      };
      await guestIdentityService
        .login(payload)
        .then((resp: AxiosResponse) => {
          currentUser = resp.data;
          const { dispatch } = dispatchHolder;
          if (!dispatch) {
            return;
          }
          // guest shopper always remembered when persistent session enabled.
          const rememberMe =
            process.env.REACT_APP_PERSISTENT_SESSION?.toLowerCase() === "true"
              ? { rememberMe: true }
              : {};
          dispatch(
            GUEST_LOGIN_SUCCESS_ACTION({
              ...currentUser,
              ...payload,
              ...rememberMe,
            })
          );
        })
        .catch((error) => {
          throw error;
        });
    }
  }
};
*/

const reportError = (error: unknown): boolean => {
  if (!isInstanaActive() || isCanceledError(error) || isAuthError(error)) {
    return false;
  }
  if (isAxiosError(error)) {
    const isLogoff =
      error.config?.url?.endsWith("loginidentity/@self") &&
      error.config?.method?.toLowerCase() === "delete";
    if (isLogoff) {
      return false;
    }

    const isEmptyCart =
      error.config?.url?.endsWith("cart/@self") &&
      error.response?.status === 404;
    if (isEmptyCart) {
      return false;
    }
  }
  return true;
};

const useAxiosInterceptor = () => {
  const dispatch = useAppDispatch();

  useLayoutEffect(() => {
    dispatchHolder.dispatch = dispatch;

    const requestInterceptor = axios.interceptors.request.use(
      async (request: InternalAxiosRequestConfig & { startTime?: number }) => {
        PrerenderTimer.myTimer.setPrerenderTimer();
        showAPIFlow(
          request.method,
          request.url,
          "widget" in request ? (request.widget as string) : undefined
        );
        processHeaders(request);
        // verify active storeId in localStorage.
        storageStoreIdHandler.verifyActiveStoreId();
        const previewToken = storageSessionHandler.getPreviewToken();
        if (
          (previewToken && previewToken[WC_PREVIEW_TOKEN]) ||
          isNumberParserRequiredService(request)
        ) {
          request.transformResponse = [transformResponse];
        }

        // await processGuestLogon(request);
        processForUserParameter(request);
        processLangIdParameter(request);
        processSetSeller(request);
        if (!request.startTime) {
          request.startTime = Date.now();
        }

        return request;
      },
      (error: AxiosError) => {
        if (reportError(error)) {
          const { config } = error as {
            config?: InternalAxiosRequestConfig & { startTime?: number };
          };
          const startTime: number = config?.startTime || Date.now();
          const endTime = Date.now();
          ineum("reportEvent", "axios interceptors request error", {
            timestamp: endTime,
            error,
            duration: endTime - startTime,
            backendTraceId: startTime.toString(16),
          });
        }
        return Promise.reject(error);
      }
    );
    const responseInterceptor = axios.interceptors.response.use(
      (response: AxiosResponse) => {
        PrerenderTimer.myTimer.setPrerenderTimer();
        return response;
      },
      (error) => {
        if (reportError(error)) {
          const { config } = error as {
            config?: InternalAxiosRequestConfig & { startTime?: number };
          };
          const startTime: number = config?.startTime || Date.now();
          const endTime = Date.now();
          ineum("reportEvent", "axios interceptors response error", {
            timestamp: endTime,
            error,
            duration: endTime - startTime,
            backendTraceId: startTime.toString(16),
          });
        }
        return processAuthError(error);
      }
    );

    // Return cleanup function to remove interceptors if necessary
    return () => {
      axios.interceptors.request.eject(requestInterceptor);
      axios.interceptors.response.eject(responseInterceptor);
    };
  }, [dispatch]);
};

const executeRequest = (request: AxiosRequestConfig) => axios(request);

export { useAxiosInterceptor, isAuthError, isCanceledError, executeRequest };
