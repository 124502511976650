import React, { FC } from "react";
import { Box, PropTypes } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import {
  StyledCardActionArea,
  UnstyledLink,
  StyledButton,
} from "components/StyledUI";
import { mapNodeToMUIComponent } from "components/widgets/content-recommendation/mapNodeToReactComponent";
import HTMLReactParser, { domToReact } from "html-react-parser";
import { ESpotData } from "types/Marketing";
import { tss } from "tss-react/mui";
import TitleCard from "./TitleCard";

const isColor = (candidate?: string): candidate is PropTypes.Color =>
  candidate != null &&
  (candidate === "inherit" ||
    candidate === "primary" ||
    candidate === "secondary" ||
    candidate === "default");

const parseDocument = (marketingText: string) => {
  const result: {
    elements: ReturnType<typeof domToReact>[];
    textBtn?: string;
    colorBtn?: PropTypes.Color;
  } = { elements: [] };

  const parsedDocument = new DOMParser().parseFromString(
    marketingText,
    "text/html"
  );
  const parsedButton = parsedDocument.querySelector("button");
  if (parsedButton) {
    const { color } = parsedButton.dataset;
    result.textBtn = parsedButton.innerHTML.trim();
    result.colorBtn = isColor(color) ? color : undefined;

    parsedButton?.remove();
  }

  const parsedImages = parsedDocument.querySelectorAll("img");
  if (parsedImages.length) {
    const src = parsedImages[0].src || undefined;
    const alt = parsedImages[0].alt || undefined;

    if (src) {
      const str = `<img src="${src}" ${alt ? `alt="${alt}"` : ""} />`;
      const parsedImage = new DOMParser().parseFromString(str, "text/html");
      const parsedHtml = HTMLReactParser(parsedImage.body.innerHTML, {
        replace: mapNodeToMUIComponent,
      });
      result.elements = [
        ...(Array.isArray(parsedHtml) ? parsedHtml : [parsedHtml]),
        ...result.elements,
      ];
    }

    // remove all images from document
    parsedImages.forEach((img) => {
      const imgParent = img?.parentNode;
      if (imgParent) {
        if (imgParent.parentNode && imgParent.childElementCount === 1) {
          imgParent.parentNode.removeChild(imgParent);
        } else {
          imgParent.removeChild(img);
        }
      }
    });
  }

  const parsedHtml = HTMLReactParser(parsedDocument.body.innerHTML, {
    replace: mapNodeToMUIComponent,
  });
  result.elements = [
    ...result.elements,
    ...(Array.isArray(parsedHtml) ? parsedHtml : [parsedHtml]),
  ];

  return result;
};

const useStyles = tss.create(({ theme }) => ({
  default: {
    cursor: "default",
  },
  contentBox: {
    lineHeight: 1,
    marginBottom: theme.spacing(2),
    backgroundColor: theme.palette.grey[50],
    borderRadius: theme.spacing(1),
    "& img": {
      width: "100%",
      borderRadius: theme.spacing(1),
    },
  },
  noBackgroundColor: {
    backgroundColor: "transparent",
  },
  dontRoundBottom: {
    borderRadius: `${theme.spacing(1, 1, 0, 0)} !important`,
  },
  htmlBox: {
    position: "relative",
    padding: theme.spacing(2),
    "& img": {
      float: "right",
      maxWidth: "80px",
      maxHeight: "80px",
    },
  },
  left: {
    [theme.breakpoints.up("lg")]: {
      maxWidth: `calc(100% - ${theme.spacing(3)})`,
      marginRight: theme.spacing(3),
    },
    "& figure": {
      maxWidth: "100%",
    },
    "& h3": {
      margin: "0 24px 0",
      lineHeight: "30px",
    },
  },
  btn: {
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
}));

type Props = {
  data: ESpotData | undefined;
  sidebar?: boolean;
};

const ContentRecommendation: FC<Props> = ({ data, sidebar = false }) => {
  const { classes, cx } = useStyles();

  if (!data) {
    return null;
  }

  return (
    <>
      {data.titles && data.titles.length > 0 && <TitleCard data={data} />}

      {data.contents.map((content) => {
        const { elements, textBtn, colorBtn } = parseDocument(
          content.marketingText || ""
        );
        return (
          <Grid
            key={`espot_${data.id}_content_${content.id}`}
            xs={12}
            className={cx({ [classes.left]: sidebar })}
            data-track-content={data.contentTracking?.content || undefined}
            data-content-name={data.contentTracking?.name || undefined}>
            <StyledCardActionArea
              className={cx({ [classes.default]: !content.url })}
              component={content.url ? UnstyledLink : Box}
              to={content.url || ""}
              data-content-piece={data.contentTracking?.piece || undefined}
              data-track-target={content.url || undefined}>
              <Box
                className={cx(classes.contentBox, {
                  [classes.noBackgroundColor]: !content.marketingText,
                })}>
                {content.attachment && content.attachment.url && (
                  <img
                    src={content.attachment.url}
                    alt=""
                    className={cx({
                      [classes.dontRoundBottom]: !content.marketingText,
                    })}
                  />
                )}
                {!!elements.length && (
                  <div className={classes.htmlBox}>{elements}</div>
                )}
                {!!textBtn && content.url && (
                  <Box className={classes.btn}>
                    <StyledButton
                      fullWidth
                      color={colorBtn !== "default" ? colorBtn : "inherit"}
                      size="small">
                      {textBtn}
                    </StyledButton>
                  </Box>
                )}
              </Box>
            </StyledCardActionArea>
          </Grid>
        );
      })}
    </>
  );
};

export default ContentRecommendation;
