import { useMemo } from "react";
import {
  customerAssignedProductLists,
  customerProductListsRaw,
} from "_redux/selectors/user";
import { useAppSelector } from "_redux/hooks";
import {
  CustomerProductlist,
  CUSTOMER_PRODUCTLIST_TYPE_LABELLIST,
  CUSTOMER_PRODUCTLIST_TYPE_ORDERSET,
  CUSTOMER_PRODUCTLIST_TYPE_REQUISITION_LIST,
  CUSTOMER_PRODUCTLIST_TYPE_STORAGE_LIST,
} from "types/CustomerProductlist";

const useCustomerProductLists = () => {
  const rawCustomerProductLists = useAppSelector(customerProductListsRaw);
  const assignedProductLists = useAppSelector(customerAssignedProductLists);

  return useMemo(() => {
    const customerProductLists: CustomerProductlist[] = [];

    const orderset = rawCustomerProductLists.find(
      (customerProductList) =>
        customerProductList.type === CUSTOMER_PRODUCTLIST_TYPE_ORDERSET
    );
    if (orderset) {
      customerProductLists.push(orderset);
    }

    const labelList = rawCustomerProductLists.find(
      (customerProductList) =>
        customerProductList.type === CUSTOMER_PRODUCTLIST_TYPE_LABELLIST
    );
    if (labelList) {
      customerProductLists.push(labelList);
    }

    const requistionAndStorageLists = rawCustomerProductLists.filter(
      (customerProductList) =>
        customerProductList.type ===
          CUSTOMER_PRODUCTLIST_TYPE_REQUISITION_LIST ||
        customerProductList.type === CUSTOMER_PRODUCTLIST_TYPE_STORAGE_LIST
    );
    if (requistionAndStorageLists.length) {
      customerProductLists.push(...requistionAndStorageLists);
    }

    return customerProductLists;
  }, [assignedProductLists, rawCustomerProductLists]);
};

export default useCustomerProductLists;
